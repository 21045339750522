const BoxOfficeTickets = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_16902_94694)"
    />
    <path
      d="M29.1667 18.3333C29.6275 18.3333 30 17.9608 30 17.5V13.3333C30 12.8725 29.6275 12.5 29.1667 12.5H10.8333C10.3725 12.5 10 12.8725 10 13.3333V17.5C10 17.9608 10.3725 18.3333 10.8333 18.3333C11.7525 18.3333 12.5 19.0808 12.5 20C12.5 20.9192 11.7525 21.6667 10.8333 21.6667C10.3725 21.6667 10 22.0392 10 22.5V26.6667C10 27.1275 10.3725 27.5 10.8333 27.5H29.1667C29.6275 27.5 30 27.1275 30 26.6667V22.5C30 22.0392 29.6275 21.6667 29.1667 21.6667C28.2475 21.6667 27.5 20.9192 27.5 20C27.5 19.0808 28.2475 18.3333 29.1667 18.3333ZM24.1667 22.5H15.8333V20.8333H24.1667V22.5ZM24.1667 19.1667H15.8333V17.5H24.1667V19.1667Z"
      fill="#00C4EB"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16902_94694"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C4EB" stopOpacity="0.25" />
        <stop offset="1" stopColor="#00C4EB" stopOpacity="0.08" />
      </linearGradient>
    </defs>
  </svg>
);

const ChooseYourSeats = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_16786_193434)"
    />
    <g clipPath="url(#clip0_16786_193434)">
      <path
        d="M15.8097 20.9164V22.3915H24.0708V20.9164C24.072 20.1072 24.3751 19.3276 24.9208 18.7301C25.4665 18.1326 26.2154 17.7602 27.0211 17.6857V12.3603C27.0211 11.7343 26.7725 11.134 26.3298 10.6913C25.8872 10.2487 25.2868 10 24.6608 10H15.2197C14.5937 10 13.9933 10.2487 13.5507 10.6913C13.108 11.134 12.8594 11.7343 12.8594 12.3603V17.6857C13.6651 17.7602 14.4141 18.1326 14.9597 18.7301C15.5054 19.3276 15.8085 20.1072 15.8097 20.9164Z"
        fill="#FF66AF"
      />
      <path
        d="M27.3171 18.8511C26.7694 18.8511 26.244 19.0687 25.8567 19.456C25.4694 19.8433 25.2518 20.3686 25.2518 20.9163V23.5717H14.6305V20.9163C14.6305 20.3686 14.4129 19.8433 14.0256 19.456C13.6383 19.0687 13.113 18.8511 12.5653 18.8511C12.0175 18.8511 11.4922 19.0687 11.1049 19.456C10.7176 19.8433 10.5 20.3686 10.5 20.9163V27.7022C10.5 27.8587 10.5622 28.0088 10.6728 28.1194C10.7835 28.2301 10.9336 28.2923 11.0901 28.2923H12.8603V30.0625H14.0404V28.2923H25.8419V30.0625H27.0221V28.2923H28.7923C28.9488 28.2923 29.0989 28.2301 29.2095 28.1194C29.3202 28.0088 29.3824 27.8587 29.3824 27.7022V20.9163C29.3824 20.3686 29.1648 19.8433 28.7775 19.456C28.3901 19.0687 27.8648 18.8511 27.3171 18.8511Z"
        fill="#FF66AF"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_16786_193434"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC0062" stopOpacity="0.25" />
        <stop offset="1" stopColor="#CC0062" stopOpacity="0.08" />
      </linearGradient>
      <clipPath id="clip0_16786_193434">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

const ExclusiveDeals = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_16902_94702)"
    />
    <path
      d="M19.4995 15V12.5H20.7495V15H23.6358L25.4122 11.4472C25.7446 10.7823 25.2611 10 24.5177 10H15.7313C14.9879 10 14.5044 10.7823 14.8369 11.4472L16.6133 15H19.4995Z"
      fill="#FFE900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1988 16.25H24.0512C25.555 17.9688 28.25 21.4531 28.25 24.375C28.25 25.6944 27.6637 30 20.125 30C12.5862 30 12 25.6944 12 24.375C12 21.4531 14.695 17.9688 16.1988 16.25ZM20.4029 19.582C20.3022 19.3311 19.9469 19.3311 19.8462 19.582L19.0453 21.5764C19.0024 21.6832 18.9021 21.7561 18.7872 21.7639L16.643 21.9093C16.3732 21.9276 16.2634 22.2654 16.4709 22.4388L18.1202 23.8168C18.2085 23.8906 18.2468 24.0085 18.2187 24.1202L17.6944 26.2044C17.6285 26.4666 17.9158 26.6754 18.1449 26.5316L19.965 25.3889C20.0626 25.3277 20.1865 25.3277 20.2841 25.3889L22.1042 26.5316C22.3332 26.6754 22.6206 26.4666 22.5547 26.2044L22.0303 24.1202C22.0022 24.0085 22.0406 23.8906 22.1289 23.8168L23.7782 22.4388C23.9857 22.2654 23.8759 21.9276 23.6061 21.9093L21.4619 21.7639C21.347 21.7561 21.2467 21.6832 21.2038 21.5764L20.4029 19.582Z"
      fill="#FFE900"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16902_94702"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE900" stopOpacity="0.25" />
        <stop offset="1" stopColor="#FFE900" stopOpacity="0.08" />
      </linearGradient>
    </defs>
  </svg>
);

const BookAndRelax = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_16786_193425)"
    />
    <path
      d="M27.668 11.5324L20.168 10.0324C20.0605 10.0107 19.9488 10.0107 19.8413 10.0324L12.3413 11.5324C11.9505 11.609 11.668 11.9515 11.668 12.3499V20.8332C11.668 27.4999 20.0013 29.9999 20.0013 29.9999C20.0013 29.9999 28.3346 27.4999 28.3346 20.8332V12.3499C28.3346 11.9532 28.0563 11.6115 27.668 11.5324ZM24.8005 17.209L19.8005 23.0424C19.6496 23.2182 19.4321 23.324 19.1996 23.3324C19.1888 23.3324 19.178 23.3324 19.1671 23.3324C18.9463 23.3324 18.7346 23.2449 18.578 23.0882L16.078 20.5882C15.7521 20.2624 15.7521 19.7357 16.078 19.4099C16.4038 19.084 16.9305 19.084 17.2563 19.4099L19.1196 21.2732L23.5338 16.1232C23.8338 15.7749 24.3605 15.7332 24.7088 16.0332C25.058 16.3324 25.0988 16.859 24.7988 17.2082L24.8005 17.209Z"
      fill="#15D676"
    />
    <defs>
      <linearGradient
        id="paint0_linear_16786_193425"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#15D676" stopOpacity="0.25" />
        <stop offset="1" stopColor="#15D676" stopOpacity="0.08" />
      </linearGradient>
    </defs>
  </svg>
);

const Calendar = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="44"
      height="44"
      rx="22"
      fill="url(#paint0_linear_9201_37776)"
    />
    <path
      d="M33 13H28V11C28 10.448 27.552 10 27 10C26.448 10 26 10.448 26 11V13H18V11C18 10.448 17.552 10 17 10C16.448 10 16 10.448 16 11V13H11C10.448 13 10 13.448 10 14V32C10 32.552 10.448 33 11 33H19C19.552 33 20 32.552 20 32C20 31.448 19.552 31 19 31H12.5C12.2239 31 12 30.7761 12 30.5V17.5C12 17.2239 12.2239 17 12.5 17H31.5C31.7761 17 32 17.2239 32 17.5V20C32 20.552 32.448 21 33 21C33.552 21 34 20.552 34 20V14C34 13.448 33.552 13 33 13Z"
      fill="#00C4EB"
    />
    <path
      d="M28 22C24.686 22 22 24.686 22 28C22 31.314 24.686 34 28 34C31.314 34 34 31.314 34 28C34 24.686 31.314 22 28 22ZM31 29H28C27.448 29 27 28.553 27 28V25C27 24.447 27.448 24 28 24C28.552 24 29 24.447 29 25V27H31C31.552 27 32 27.447 32 28C32 28.553 31.552 29 31 29Z"
      fill="#00C4EB"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9201_37776"
        x1="22"
        y1="0"
        x2="22"
        y2="44"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00C4EB" stopOpacity="0.25" />
        <stop offset="1" stopColor="#00C4EB" stopOpacity="0.08" />
      </linearGradient>
    </defs>
  </svg>
);

const LastMinTix = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="url(#paint0_linear_9201_23161)"
    />
    <path
      d="M29.1667 18.3333C29.6275 18.3333 30 17.9608 30 17.5V13.3333C30 12.8725 29.6275 12.5 29.1667 12.5H10.8333C10.3725 12.5 10 12.8725 10 13.3333V17.5C10 17.9608 10.3725 18.3333 10.8333 18.3333C11.7525 18.3333 12.5 19.0808 12.5 20C12.5 20.9192 11.7525 21.6667 10.8333 21.6667C10.3725 21.6667 10 22.0392 10 22.5V26.6667C10 27.1275 10.3725 27.5 10.8333 27.5H29.1667C29.6275 27.5 30 27.1275 30 26.6667V22.5C30 22.0392 29.6275 21.6667 29.1667 21.6667C28.2475 21.6667 27.5 20.9192 27.5 20C27.5 19.0808 28.2475 18.3333 29.1667 18.3333ZM24.1667 22.5H15.8333V20.8333H24.1667V22.5ZM24.1667 19.1667H15.8333V17.5H24.1667V19.1667Z"
      fill="#FF66AF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9201_23161"
        x1="20"
        y1="0"
        x2="20"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CC0062" stopOpacity="0.25" />
        <stop offset="1" stopColor="#CC0062" stopOpacity="0.08" />
      </linearGradient>
    </defs>
  </svg>
);

export const V2TrustBoosters: Record<string, JSX.Element> = {
  BOX_OFFICE_TICKETS: BoxOfficeTickets,
  CHOOSE_YOUR_SEATS: ChooseYourSeats,
  EXCLUSIVE_DEALS: ExclusiveDeals,
  BOOK_AND_RELAX: BookAndRelax,
  CALENDAR: Calendar,
  LAST_MIN: LastMinTix,
};
